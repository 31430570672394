import React from 'react';
import Favicon from '../Favicon';
import HeaderBanner from '@/ui/shared/components/Layout/HeaderBanner';
import CheckAccessPermissions from '@/users/application/Session/CheckAccessPermissions';
import TenantThemeContextProvider from '@/users/application/TenantThemeContext';
import Animation from '@/ui/shared/components/Landing/Sections/Animation';
import { ITenantTheme } from '@/users/domain/vo/ITenantTheme';
import styles from './layout.scss';

interface IProps {
  children: React.ReactElement,
  withBanner?: boolean,
  onInit: () => void,
  tenantTheme?: ITenantTheme,
}

const Layout = (
  {
    children,
    withBanner,
    onInit,
    tenantTheme,
  }: IProps,
) => (
  <>
    <Favicon />
    { withBanner && (
      <HeaderBanner shouldUseContentWrapAsScrollable />
    ) }
    { onInit ? (
      <CheckAccessPermissions onInit={ onInit }>
        <TenantThemeContextProvider tenantTheme={ tenantTheme }>
          <div className={ styles.wrapper }>
            { tenantTheme?.background?.animationParams && (
              <Animation parameters={ tenantTheme.background.animationParams } />
            ) }
            { children }
          </div>
        </TenantThemeContextProvider>
      </CheckAccessPermissions>
    ) : (
      children
    ) }
  </>
);

export default Layout;
