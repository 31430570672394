import { createContext, useContext } from 'react';
import useTenantTheme from './useTenantTheme';
import { ITenantTheme } from '@/users/domain/vo/ITenantTheme';

type TenantThemeContextType = ReturnType<typeof useTenantTheme>;

export const TenantThemeContext = createContext<TenantThemeContextType>(null);

export const useTenantThemeContext = (): TenantThemeContextType => {
  const context = useContext<TenantThemeContextType>(TenantThemeContext);
  if (!context) {
    throw new Error('useTenantThemeContext must be used within a TenantThemeContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
  tenantTheme?: ITenantTheme,
}

const TenantThemeContextProvider = ({ children, tenantTheme }: IProps) => (
  <TenantThemeContext.Provider value={ useTenantTheme(tenantTheme) }>
    { children }
  </TenantThemeContext.Provider>
);

export default TenantThemeContextProvider;
